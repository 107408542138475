<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="17.25" cy="17.25" r="6" />

        <path
            class="a"
            d="M19.924,15.506l-2.905,3.873a.752.752,0,0,1-1.131.081l-1.5-1.5"
        />

        <path
            class="a"
            d="M8.25,15.75h-6a1.5,1.5,0,0,1-1.5-1.5v-12A1.5,1.5,0,0,1,2.25.75h18a1.5,1.5,0,0,1,1.5,1.5V9"
        />

        <path
            class="a"
            d="M21.411,1.3,13.267,7.564a3.308,3.308,0,0,1-4.034,0L1.089,1.3"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'EmailCheckIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
