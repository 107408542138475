<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M17.505,15.862A7.369,7.369,0,0,0,18.75,18.75H.75s1.5-2.329,1.5-8.25A7.5,7.5,0,0,1,9.75,3V.75"
        />

        <circle class="a" cx="17.25" cy="6.75" r="6" />

        <polyline class="a" points="17.25 4.5 17.25 8.25 19.5 8.25" />

        <path class="a" d="M7.748,21.75a2.087,2.087,0,0,0,4.005,0" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'AlarmBellIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
